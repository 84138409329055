<template>
  <div class="billing-page-container">
    <div class="md-layout billing-page">
      <div class="md-layout-item billing-item" :class="activePlan(plan0Id) + activePlan(null)">
        <md-card class="price-card md-elevation-3">
          <md-card-header-text>
              <div class="plan-title">FreeFaves</div>
          </md-card-header-text>
          <md-card-header-text>
              <div class="plan-price"><span class="">$</span>0<sub class="sub"><span class="spacer">/</span>month</sub></div>
          </md-card-header-text>
          <md-card-content class="price-card-content">
            <br>
            <p class="md-subheading">
              <img src="/billing/x.svg"> {{ limitedTabs(0) }}
            </p>
            <br>
            <p class="md-subheading">
              <img src="/billing/x.svg"> Priority logo creation
            </p>
            <br>
            <p class="md-subheading">
              <img src="/billing/x.svg"> Remove ads
            </p>
            <br>
          </md-card-content>
          <div class="free-plan-current-container" v-if="!hasSubscription">
              <img src="/billing/circle-check.svg"> &nbsp;<p class="free-plan-current"> Your current plan </p>
          </div>
        </md-card>
      </div>
      <div class="md-layout-item billing-item" :class="activePlan(plan1Id)">
        <md-card class="price-card md-elevation-3">
          <md-card-header-text>
              <div class="plan-title">Monthly</div>
          </md-card-header-text>
          <md-card-header-text>
              <div class="plan-price"><span class="">$</span>8<sub class="sub"><span class="spacer">/</span>month</sub></div>
          </md-card-header-text>
          <md-card-content class="price-card-content">
            <br>
            <p class="md-subheading">
              <img src="/billing/check.svg"> &nbsp;{{ limitedTabs(1) }}
            </p>
            <br>
            <p class="md-subheading">
              <img src="/billing/check.svg"> &nbsp;Priority logo creation
            </p>
            <br>
            <p class="md-subheading">
              <img src="/billing/check.svg"> &nbsp;Remove ads
            </p>
            <br>
          </md-card-content>
          <md-card-actions v-if="!hasSubscription">
            <span class="center-span">
              <md-button class="md-primary plan-btn" @click="purchase(plan1Id)">Choose plan</md-button>
            </span>
          </md-card-actions>
          <md-card-actions v-else-if="this.user.plan_id == plan1Id">
            <span class="center-span">
              <md-button class="md-primary plan-btn" v-bind:disabled="hasCanceled"
              @click="cancelSubscription()">Cancel</md-button>
            </span>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item billing-item" :class="activePlan(plan2Id)">
        <md-card class="price-card md-elevation-3">
          <md-card-header-text>
              <div class="plan-title">Yearly <span class="yearly-off">%39 OFF</span></div>
          </md-card-header-text>
          <md-card-header-text>
              <div class="plan-price"><span class="">$</span>55<sub class="sub"><span class="spacer">/</span>year</sub></div>
          </md-card-header-text>
          <md-card-content class="price-card-content">
            <br>
            <p class="md-subheading">
              <img src="/billing/check.svg"> &nbsp;{{ limitedTabs(2) }}
            </p>
            <br>
            <p class="md-subheading">
              <img src="/billing/check.svg"> &nbsp;Priority logo creation
            </p>
            <br>
            <p class="md-subheading">
              <img src="/billing/check.svg"> &nbsp;Remove ads
            </p>
            <br>
          </md-card-content>
          <md-card-actions class="" v-if="!hasSubscription">
            <span class="center-span">
              <md-button class="md-primary plan-btn" @click="purchase(plan2Id)">Choose plan</md-button>
            </span>
          </md-card-actions>
          <md-card-actions v-else-if="this.user.plan_id == plan2Id">
            <span class="center-span">
              <md-button class="md-primary plan-btn" v-bind:disabled="hasCanceled" @click="cancelSubscription()">Cancel</md-button>
            </span>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <md-card-content v-if="hasSubscription">
        <p class="md-subheading">
          <i class="fas fa-calendar"></i> Subscription date: <span class="date"> {{ userSubDate }} </span>
        </p>
        <p class="md-subheading">
          <i class="fas fa-calendar-plus"></i> Next payment date: <span class="date"> {{ userNextPaymentDate }} </span>
        </p>
    </md-card-content>
    <md-card-content v-else>
      <div v-if="this.user && this.user.end_grace_date">
        <p class="md-subheading">
          <i class="fas fa-calendar"></i> Your don't have an active plan but your previous subscription is still valid until: <span class="date"> {{ this.user.end_grace_date }} </span>
        </p>
      </div>
    </md-card-content>
  </div>
</template>

<script>
import PaymentsService from '@/services/payments.service';
import { GET_AUTH_USER_TABS } from '@/store/modules/auth/authUserTabs/authUserTabsActions.type';

export default {
  name: 'Billing',
  data() {
    return {
      plans: [],
      monthly: 0,
      yearly: 0,
      hasCanceled: false,
    };
  },
  methods: {
    purchase(planId) {
      const subscriptionPlan = this.plans.find(plan => plan.id === planId);
      this.$router.push({
        name: 'purchase',
        params: { subscriptionPlan },
      });
    },
    cancelSubscription() {
      this.hasCanceled = true;
      PaymentsService.cancelSubscription()
        .then(() => {
          this.$toasted.show('Your purchase was canceled successfully.', {
            type: 'success',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
          });
          this.$store.dispatch(GET_AUTH_USER_TABS).then();
        })
        .catch((error) => {
          this.$toasted.show(error, {
            type: 'error',
            position: 'top-right',
            duration: 5000,
            icon: 'alert',
          });
          this.hasCanceled = false;
        });
    },
  },
  computed: {
    plan0Id() {
      return this.plans[0] !== undefined ? this.plans[0].id : 1;
    },
    plan1Id() {
      return this.plans[1] !== undefined ? this.plans[1].id : 2;
    },
    plan2Id() {
      return this.plans[2] !== undefined ? this.plans[2].id : 3;
    },
    monthlyPrice() {
      return this.monthly.toString().concat(' $');
    },
    yearlyPrice() {
      return this.yearly.toString().concat(' $');
    },
    user() {
      return this.$store.getters.user;
    },
    userSubDate() {
      return this.$store.getters.user.subscription_date;
    },
    userNextPaymentDate() {
      return this.$store.getters.user.next_payment_date;
    },
    hasSubscription() {
      return this.user.plan_id != null && this.user.plan_id !== 1 && this.user.end_grace_date == null;
    },
    activePlan() {
      // either the free plan or the subscribed one
      return id => (((this.user.plan_id == null && id == null) || (id === this.user.plan_id && this.hasSubscription)) ? 'active-billing-option' : '');
    },
    limitedTabs() {
      return index => ((index !== null && this.plans.length > 0 && this.plans[index].tabs_number != null)
        ? `${this.plans[index].tabs_number} Limited tabs and sites`
        : 'Unlimited tabs and sites');
    },
  },
  mounted() {
    PaymentsService.getPlans().then((res) => {
      [this.plans] = res.data.data;
      this.monthly = this.plans[1] != null ? this.plans[1].amount : 8;
      this.yearly = this.plans[2] != null ? this.plans[2].amount : 55;
    });
  },
};
</script>

<style lang="scss">
  @import '@/assets/scss/layout/user-dashboard.scss';
  @import '@/assets/scss/layout/billing.scss';
</style>
